import styled from 'styled-components';

interface CampaignTypeProps {
  selected: boolean;
}

export const Container = styled.div<CampaignTypeProps>`
  flex-direction: column;
  justify-content: center;
  border: ${({ selected }) => (selected ? 2 : 1)}px solid
    ${({ selected }) => (selected ? '#A926FC' : '#C0B8D0')};
  height: 200px;
  width: 270px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #fff;

  box-shadow: ${({ selected }) =>
    selected ? '0px 18px 22px -12px rgba(169, 38, 252, 0.2);' : 'none'};

  span {
    font-weight: 500;
    font-size: 14px;
    color: #0c2556;
    margin-top: 24px;
    font-family: 'Poppins', sans-serif;
  }

  @media (max-width: 1024px) {
    img {
      width: 50px;
    }

    span {
      margin-left: 4px;
    }
  }
`;
