import { useCallback, useEffect, useState, memo } from 'react';
import Form from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { confirmSignup, signup, SignupInput } from 'store/ducks/user';
import CodeInput from 'components/CodeInput';
import { ReduxStore } from 'interfaces/reduxStore';
import { UserState } from 'interfaces/user';
import { notification } from 'antd';
import { useQuery } from 'hooks/useQuery';
import { translate } from 'config/i18n';
import { FormContainer, HighlightText, Title } from '../styles';
import { FormTitle, ButtonContainer, Button } from './styles';
import { LoginLayout } from '../Layout';

const CODE_LENGTH = 6;

const resendCodeCallback = () => {
  notification.success({
    message: translate('general_messages.code_successfully_sent'),
  });
};

function ConfirmSignUp() {
  const history = useHistory();
  const query = useQuery();
  const { loading } = useSelector<ReduxStore, UserState>(state => state.user);
  const [code, setCode] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  const phone = query.get('phone');
  const email = query.get('email');

  const handleCodeChange = useCallback((value: string[]) => {
    setCode(value);
  }, []);

  const handleResend = useCallback(() => {
    let resendInput: SignupInput = {
      redirect: redirectUrl,
    };
    if (phone) resendInput = { ...resendInput, phone: phone.replace(' ', '+') };
    if (email) resendInput = { ...resendInput, email };

    dispatch(signup(resendInput, resendCodeCallback));
  }, [dispatch, phone, email, redirectUrl]);

  const handleAlreadyConfirmed = useCallback(() => {
    history.push(redirectUrl || '/establishment');
  }, [history, redirectUrl]);

  useEffect(() => {
    const stringCode = code.join('');
    if (stringCode.length === CODE_LENGTH) {
      dispatch(
        confirmSignup(
          stringCode.toUpperCase(),
          () => {
            history.push(
              `/signup/complete${
                window.location.search ? `${window.location.search}&` : '?'
              }code=${stringCode}`,
            );
          },
          () => {
            handleCodeChange([]);
          },
        ),
      );
    }
  }, [code, dispatch, history, handleCodeChange]);

  return (
    <LoginLayout>
      <FormContainer>
        <Title>
          <HighlightText strong>
            {translate('auth.little_missing')}
          </HighlightText>
          {'\n'}
          {translate('auth.validate_your_registration_below')}
        </Title>
        <FormTitle>{translate('auth.insert_the_code_sent')}</FormTitle>
        <Form>
          <CodeInput
            disabled={loading}
            onChange={handleCodeChange}
            value={code}
            length={CODE_LENGTH}
          />
        </Form>
        <ButtonContainer justify="space-around">
          {(phone || email) && (
            <Button
              disabled={loading}
              onClick={handleResend}
              htmlType="submit"
              type="default"
              size="large"
            >
              {translate('auth.resend_code')}
            </Button>
          )}
          <Button
            onClick={handleAlreadyConfirmed}
            htmlType="submit"
            type="default"
            size="large"
          >
            {translate('auth.already_confirmed_by_link')}
          </Button>
        </ButtonContainer>
      </FormContainer>
    </LoginLayout>
  );
}

export default memo(ConfirmSignUp);
