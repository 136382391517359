import styled from 'styled-components';
import { Col, Row, Typography, Button } from 'antd';
import background from 'assets/images/fanapp-image.png';
import logo from 'assets/images/logo-fanapp-horizontal-cromatica-positive.png';

export const Container = styled(Row)`
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
`;

export const Align = styled.div`
  flex: 1;
`;

export const HeaderContainer = styled.header`
  margin-bottom: 3.1rem;
`;

export const BackgroundCol = styled(Col)`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 2;
    display: block;
  }
`;

export const Background = styled.img.attrs({
  src: background,
  alt: 'fan background concert',
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Logo = styled.img.attrs({
  alt: 'logo fan app',
  src: logo,
})``;

export const FormCol = styled(Col)`
  padding-top: 2rem;
  padding-left: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: @screen-md) {
    padding-left: 2rem;
  }
`;

export const HighlightText = styled(Typography.Text)`
  color: @primary-color;
`;

export const FooterText = styled.h2`
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 0.7rem;
  color: #a8a9ac;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 0;
  font-weight: 300;
  line-height: 2.4rem;
  white-space: pre-line;
`;

export const FormTitle = styled.h2`
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

export const FormContainer = styled.div`
  max-width: 450px;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 2rem;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  height: 55px;
`;
