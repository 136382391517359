import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.nav`
  height: 32px;
  margin-top: 15px;
  display: flex;
`;

export const TabContainer = styled.div`
  position: relative;
  margin-right: 15px;

  &.active::after {
    content: '';
    height: 3px;
    width: 100%;
    position: absolute;
    background-color: #a926fc;
    top: 30px;
    left: 0;
  }
`;

export const TabItem = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  color: #0c2556;
  position: relative;
  display: flex;
`;

export const CounterCard = styled.div`
  background: #229dff;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 0 5px;
  border-radius: 5px;
  margin-left: 8px;
  height: 18px;
  top: 4px;
  position: relative;
`;
