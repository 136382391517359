import styled, { css } from 'styled-components';

interface ImageUploadProps {
  isDragActive: boolean;
  hasLogoPreview: boolean;
  $width: number | undefined;
  rounded: boolean | undefined;
  $loading: boolean | undefined;
  hasPreview: boolean | undefined;
}

export const Indicator = styled.div`
  background: #080f38;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateX(40%);
  transform: translateY(40%);

  img {
    width: 70% !important;
    height: 70% !important;
    margin: 0 !important;
    filter: brightness(0) invert(1);
  }
`;

export const ImageUpload = styled.div<ImageUploadProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: calc(100% - 24px);
  padding: 30px;
  background: #fff0ea;
  position: relative;
  outline: 0;

  ${props =>
    props.rounded
      ? css`
          border-radius: 50%;
        `
      : css`
          border-radius: 5px;
        `}

  .anticon.anticon-cloud-upload {
    ${props =>
      props.rounded
        ? css`
            font-size: 40px;
            left: -4px;
            position: relative;
            top: -2px;
          `
        : css`
            font-size: 100px;
          `}
  }

  ${props =>
    props.isDragActive
      ? css`
          border: thick dashed #080f38;
        `
      : css`
          border: thick dashed transparent;
        `}

  ${props =>
    props.hasLogoPreview &&
    css`
      padding: 0;
      background: transparent;
    `}

  ${props =>
    props.$width &&
    css`
      width: ${props.$width}px;
    `}
  ${props =>
    props.$width &&
    css`
      height: ${props.$width}px;
    `}

  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${props =>
      props.rounded
        ? css`
            border-radius: 50%;
          `
        : css`
            border-radius: 5px;
          `}

    ${props =>
      props.$loading
        ? css`
            opacity: 0.6;
          `
        : css`
            opacity: 1;
          `}
  }

  p {
    margin: 20px 0 0;
    ${props =>
      props.rounded &&
      css`
        display: none;
      `}
  }

  button.image-clear-btn {
    outline: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #080f38;
    border: 1px solid #080f38 !important;
    font-weight: bold;
    cursor: pointer;
    position: absolute !important;
    background: white !important;
    padding-top: 5px;
    top: 0;
    right: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    span {
      top: -4px;
      position: relative;
    }
  }

  button.image-clear-btn.rounded {
    right: -6px;

    span {
      top: -8px;
      right: -0px;
    }
  }
`;
