import { useCallback, useState } from 'react';
import { Form, Row, Input } from 'antd';
import { UserState } from 'interfaces/user';
import { login, LoginInput } from 'store/ducks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { Store } from 'antd/lib/form/interface';
import PhoneInput from 'react-phone-input-2';
import { useQuery } from 'hooks/useQuery';
import { usePermissions } from 'hooks/usePermissions';
import { SubmitButton } from '../styles';
import {
  ForgetPasswordLink,
  InputTitle,
  FormTitle,
  Check,
  CheckText,
} from './styles';

function PhoneForm(): JSX.Element {
  const dispatch = useDispatch();
  const { addLoggedUser } = usePermissions();
  const { clearCurrentEstablishment } = usePermissions();
  const query = useQuery();
  const { loading } = useSelector<ReduxStore, UserState>(state => state.auth);
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  const handleForm = useCallback(
    ({ phone, password, persist }: Store) => {
      const input: LoginInput = {
        phone: `+${phone}`,
        password,
      };

      clearCurrentEstablishment();
      dispatch(
        login(input, persist, user => {
          if (redirectUrl) window.location.href = redirectUrl;
          addLoggedUser(user);
        }),
      );
    },
    [dispatch, redirectUrl, clearCurrentEstablishment, addLoggedUser],
  );

  return (
    <Form onFinish={handleForm}>
      <FormTitle>Telefone</FormTitle>
      <Form.Item
        name="phone"
        validateFirst
        rules={[
          {
            required: true,
            message: 'O campo telefone é obrigatório',
            validateTrigger: 'onBlur',
          },
          {
            min: 12,
            message:
              'Seu telefone deve conter no mínimo 12 dígitos, incluindo código do pais e DDD',
            validateTrigger: 'onBlur',
          },
        ]}
      >
        <PhoneInput country="br" inputClass="ant-input" />
      </Form.Item>

      <InputTitle>Senha</InputTitle>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'O campo senha é obrigatório',
            validateTrigger: 'onBlur',
          },
          {
            min: 6,
            message: 'O campo senha deve conter no mínimo 6 caracteres',
            validateTrigger: 'onBlur',
          },
        ]}
      >
        <Input.Password placeholder="Senha" />
      </Form.Item>

      <Row justify="space-between" align="middle">
        <Form.Item valuePropName="checked" name="persist">
          <Check>
            <CheckText>Manter conectado</CheckText>
          </Check>
        </Form.Item>

        <ForgetPasswordLink href={`/forgot-password?redirect=${redirectUrl}`}>
          Esqueceu a senha?
        </ForgetPasswordLink>
      </Row>

      <Row justify="center">
        <SubmitButton
          disabled={loading}
          htmlType="submit"
          type="primary"
          size="large"
        >
          {loading ? 'Enviando' : 'Confirmar'}
        </SubmitButton>
      </Row>
    </Form>
  );
}

export default PhoneForm;
