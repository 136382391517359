import { memo, useEffect, useState, useCallback } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { ReduxStore } from 'interfaces/reduxStore';
import { Establishment, EstablishmentState } from 'interfaces/establishment';
import api from 'services/api';
import { getEstablishmentName, getNamePlaceholder } from 'helpers/stringHelper';
import { setCurrentEstablishment } from 'store/ducks/establishment';
import LoadingImg from 'assets/images/loading.gif';
import { translate } from 'config/i18n';
import { usePermissions } from 'hooks/usePermissions';
import { Modal, ListItem, HighlightText } from './styles';

interface ModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function SuperFilter({ visible, setVisible }: ModalProps) {
  const { addCurrentEstablishment } = usePermissions();
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const [establishments, setEstablishments] = useState<Array<Establishment>>(
    [],
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getEstablishmentsFromServer() {
      try {
        const { data } = await api.get('me/establishments');
        setEstablishments(
          data.data
            .filter((x: Establishment) => x.id !== currentEstablishment?.id)
            .slice(0, 3),
        );
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    }

    getEstablishmentsFromServer();
  }, [currentEstablishment]);

  const handleChangeEstablishment = useCallback(
    (establishment: Establishment) => {
      addCurrentEstablishment(establishment);
      dispatch(
        setCurrentEstablishment(establishment, () => {
          history.push('/establishment');
          setVisible(false);
        }),
      );
    },
    [dispatch, history, setVisible, addCurrentEstablishment],
  );

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        <div className="placeholder">
          {currentEstablishment?.logo ? (
            <div
              style={{
                backgroundImage: `url(${currentEstablishment.logo.location})`,
              }}
            />
          ) : (
            <span>{getNamePlaceholder(currentEstablishment)}</span>
          )}
        </div>
        <span>{getEstablishmentName(currentEstablishment)}</span>
      </div>
      <div className="modal-content">
        {loading ? (
          <img src={LoadingImg} alt={translate('general.loading')} />
        ) : (
          <>
            <ListItem>
              {establishments.map(x => (
                <li key={x.id}>
                  <button
                    type="button"
                    className="btn-clean"
                    onClick={() => handleChangeEstablishment(x)}
                  >
                    <div className="placeholder default">
                      {x.logo ? (
                        <div
                          style={{ backgroundImage: `url(${x.logo.location})` }}
                        />
                      ) : (
                        <span>{getNamePlaceholder(x)}</span>
                      )}
                    </div>
                    <span>{getEstablishmentName(x)}</span>
                  </button>
                </li>
              ))}
            </ListItem>
            <Link to="/establishments">{translate('general.see_all')}</Link>
          </>
        )}
      </div>
      <Link to="/establishments/create">
        <HighlightText strong>
          <span>+</span> {translate('establishment.insert_new_establishment')}
        </HighlightText>
      </Link>
    </Modal>
  );
}

export default memo(SuperFilter);
