import styled, { css } from 'styled-components';
import { Button as btn, Dropdown as DefaultDropdown, Menu } from 'antd';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const Button = styled(btn)`
  max-width: 240px;
`;

export const Content = styled.div`
  table tbody tr td,
  table thead tr th {
    text-align: center !important;
  }

  .favorite-icon {
    color: #fe6a21;
    font-size: 24px;
  }

  .actions-icon {
    font-size: 24px;
  }
`;

export const Dropdown = styled(DefaultDropdown)`
  cursor: pointer;
  padding: 0;

  ul {
    padding: 0;
  }
`;
interface CampaignTypeCardProps {
  type: string;
}

export const CampaignTypeCard = styled.div<CampaignTypeCardProps>`
  padding: 6px 15px;
  border-radius: 5px;
  font-size: 14px;
  width: fit-content;
  margin: 0 auto;

  ${({ type }) => {
    if (type === 'ticket')
      return css`
        background: #26bac620;
        color: #26bac6;
      `;
    if (type === 'credit')
      return css`
        background: #ed7d2b20;
        color: #ed7d2b;
      `;

    if (type === 'product')
      return css`
        background: #a926fc20;
        color: #a926fc;
      `;

    return css`
      opacity: 0;
    `;
  }}
`;

interface DropdownStatusProps {
  status: string;
}

export const DropdownStatus = styled(DefaultDropdown)<DropdownStatusProps>`
  cursor: pointer;
  display: initial;
  padding: 6px 15px;
  border-radius: 5px;
  font-size: 14px;

  .ml-1 {
    margin-left: 10px;
  }

  ${({ status }) => {
    if (status === 'Limitada')
      return css`
        background: #09caf020;
        color: #09caf0;
      `;
    if (status === 'Publicada')
      return css`
        background: #59df3c20;
        color: #59df3c;
      `;

    return css`
      background: #f1f1f1;
      color: #67687a;
    `;
  }}

  ul {
    padding: 0;
  }
`;

export const MenuItem = styled(Menu.Item)`
  margin: 0;
  padding: 5px 20px;
  position: relative;
`;

export const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  div.last {
    width: 100%;
    display: flex;

    img {
      top: 0px;
    }
  }

  img {
    position: relative;
    top: -1px;
    margin: 0 5px;
  }

  span {
    font-weight: 600;
    color: #000;
    margin-right: auto;
  }
`;
