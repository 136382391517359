/* eslint-disable react/jsx-props-no-spreading */
import { Row } from 'antd';
import { Link } from 'react-router-dom';
import { translate } from 'config/i18n';
import { TabBarItem, Tabs } from './styles';
import { Title, HighlightText, FormContainer } from '../styles';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import { LoginLayout } from '../Layout';

const { TabPane } = Tabs;

function SignUp(): JSX.Element {
  return (
    <LoginLayout>
      <FormContainer>
        <Title>
          {translate('auth.create_your_account_and_begin_to_use')}{' '}
          <HighlightText strong>FanApp</HighlightText>
        </Title>
        <Tabs defaultActiveKey="1" tabBarGutter={0}>
          <TabPane
            key="1"
            tab={<TabBarItem>{translate('auth.email')}</TabBarItem>}
          >
            <EmailForm />
          </TabPane>
          <TabPane
            key="2"
            tab={<TabBarItem>{translate('auth.phone')}</TabBarItem>}
          >
            <PhoneForm />
          </TabPane>
        </Tabs>
        <Row justify="center">
          <Link to={`/${window.location.search}`}>
            <HighlightText strong>{translate('general.cancel')}</HighlightText>
          </Link>
        </Row>
      </FormContainer>
    </LoginLayout>
  );
}

export default SignUp;
