import React, { useState, memo } from 'react';
import Page from 'components/Page';
import { Container } from './styles';
import CampaignModal from './CampaignModal';

function Dashboard() {
  const [confirmModalVisible, setConfirmModalVisible] = useState(true);

  return (
    <Page title="Dashboard" SubHeader={<></>}>
      <Container />
      <CampaignModal
        loading={false}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        confirmCallback={() => setConfirmModalVisible(false)}
      />
    </Page>
  );
}

export default memo(Dashboard);
