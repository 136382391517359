import { memo, useState, useCallback } from 'react';
import { Col, notification, Row, Form, DatePicker } from 'antd';

import { translate } from 'config/i18n';
import { gutter } from 'constants/styles';
import { Campaign } from 'interfaces/campaign';
import { useDispatch } from 'react-redux';
import { extendPeriod } from 'store/ducks/campaign';
import moment, { Moment } from 'moment';
import { Modal, Button, Label } from './styles';

interface ModalProps {
  campaign: Campaign;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callback?: (endAt: string) => void;
}

function AddStockModal({
  campaign,
  visible,
  setVisible,
  callback,
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [endAt, setEndAt] = useState<Moment | undefined>();
  const dispatch = useDispatch();

  const resetFields = useCallback(() => {
    setEndAt(undefined);
  }, []);

  const handleSubmit = useCallback(async () => {
    function handleNotification(description: string) {
      notification.error({
        message: translate('general.error'),
        description,
      });
    }

    if (!endAt) {
      handleNotification(
        translate('validation_messages.required_field', {
          field: 'campaign.endAt',
        }),
      );
      return;
    }
    if (endAt <= moment().startOf('day')) {
      handleNotification(
        translate('validation_messages.date_must_be_grater_than_today', {
          grater: 'campaign.endAt',
        }),
      );
      return;
    }
    if (endAt <= moment(campaign.startAt).startOf('day')) {
      handleNotification(
        translate('validation_messages.date_must_be_grater_than_field', {
          grater: 'campaign.endAt',
          smaller: 'campaign.startAt',
        }),
      );
      return;
    }
    if (endAt > moment(campaign.validDate).startOf('day')) {
      handleNotification(
        translate('validation_messages.date_must_be_smaller_than_field', {
          smaller: 'campaign.endAt',
          grater: 'campaign.validDate',
        }),
      );
      return;
    }

    try {
      setLoading(true);

      dispatch(
        extendPeriod(endAt.toString(), campaign.id, () => {
          notification.success({
            message: translate('campaign.was_saved'),
            description: translate('general_messages.was_saved', {
              context: 'Campanha',
            }),
          });
        }),
      );

      if (callback) callback(endAt.toISOString());

      setVisible(false);
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, [setVisible, campaign, callback, endAt, dispatch]);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      afterClose={resetFields}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">Extender campanha</div>
      <div className="modal-content">
        <Row gutter={gutter}>
          <Col sm={24}>
            <Label>Fim da campanha</Label>
            <Form.Item name="endAt">
              <DatePicker
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                placeholder="Quando?"
                onChange={value =>
                  value ? setEndAt(value.startOf('day')) : ''
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="align-center">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.done')}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(AddStockModal);
