import React, { memo } from 'react';
import {
  Modal,
  ModalContainer,
  Title,
  SubTitle,
  HeaderDetails,
  ItemTitle,
  ItemInfo,
  RulesContainer,
  RuleContainer,
  RowContainer,
  CardRulesContainer,
  Card,
  CardValue,
  CardsContainer,
  FirstCard,
  WhiteCardValue,
  WhiteItemTitle,
  Inactive,
  CardTitle,
  RulesTitle,
  ActiveLabel,
  ImageContainer,
} from './styles';
import TotalCreditIcon from '../../../../assets/svg/TotalCreditIcon.svg';
import TotalSaleIcon from '../../../../assets/svg/TotalSaleIcon.svg';
import TotalValueIcon from '../../../../assets/svg/TotalValueIcon.svg';
import TotalPixIcon from '../../../../assets/svg/TotalPixIcon.svg';

interface ModalProps {
  visible: boolean;
  loading: boolean;
  setVisible: (visible: boolean) => void;
  confirmCallback: () => void;
}

function CampaignModal({
  visible,
  loading,
  setVisible,
  confirmCallback,
}: ModalProps) {
  const rules = [
    { title: 'Valor fixo', value: 100 },
    { title: 'Limite por transação', value: false },
    { title: 'Limite por cliente', value: 100 },
    { title: 'Limite por transação', value: false },
    { title: 'Taxa de conveniência', value: false },
    { title: 'Gerar voucher', value: false },
    { title: 'Requisitar telefone', value: false },
    { title: 'Transação única', value: false },
  ];

  const cards = [
    { title: 'Valor total', value: 'R$ 3500,00', icon: TotalValueIcon },
    { title: 'Total de vendas', value: '200', icon: TotalSaleIcon },
    { title: 'Valor em crédito', value: 'R$ 3000,00', icon: TotalCreditIcon },
    { title: 'Valor em PIX', value: 'R$ 500,00', icon: TotalPixIcon },
  ];

  function separateArrayEveryTwo(
    array: { title: string; value: number | boolean }[],
  ) {
    const separatedArray = [];
    for (let i = 0; i < array.length; i += 2) {
      separatedArray.push(array.slice(i, i + 2));
    }
    return separatedArray;
  }

  return (
    <Modal
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
      bodyStyle={{
        backgroundColor: '#F8F8F9',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 50,
        paddingTop: 50,
        paddingLeft: 80,
        paddingRight: 80,
      }}
    >
      <Title>Campanha X</Title>
      <CardRulesContainer>
        <ModalContainer>
          <SubTitle>Detalhes da campanha</SubTitle>
          <HeaderDetails>
            <ItemTitle>Tipo de campanha</ItemTitle>
            <ItemInfo>Crédito</ItemInfo>
          </HeaderDetails>
          <RulesContainer>
            <RulesTitle>Ativação de regras</RulesTitle>
            {separateArrayEveryTwo?.(rules).map(array => (
              <RowContainer>
                <RuleContainer>
                  <div style={{ display: 'flex' }}>
                    <ItemTitle>{array[0].title}</ItemTitle>
                    {array?.[0]?.value && <ActiveLabel>Ativo</ActiveLabel>}
                  </div>
                  {array?.[0]?.value ? (
                    <ItemInfo>{`R$ ${array?.[0]?.value}`}</ItemInfo>
                  ) : (
                    <Inactive>Não ativo</Inactive>
                  )}
                </RuleContainer>
                <RuleContainer>
                  <div style={{ display: 'flex' }}>
                    <ItemTitle>{array[1].title}</ItemTitle>
                    {array?.[1]?.value && <ActiveLabel>Ativo</ActiveLabel>}
                  </div>
                  {array?.[1]?.value ? (
                    <ItemInfo>{`R$ ${array?.[1]?.value}`}</ItemInfo>
                  ) : (
                    <Inactive>Não ativo</Inactive>
                  )}
                </RuleContainer>
              </RowContainer>
            ))}
          </RulesContainer>
        </ModalContainer>
        <CardsContainer>
          {cards.map((cardInfo, key) =>
            key === 0 ? (
              <FirstCard>
                <ImageContainer>
                  <img
                    alt={`${cardInfo.title} icon`}
                    src={cardInfo.icon}
                    style={{ width: 25, height: 25, marginLeft: 20 }}
                  />
                  <WhiteItemTitle>{cardInfo.title}</WhiteItemTitle>
                </ImageContainer>
                <WhiteCardValue>{cardInfo.value}</WhiteCardValue>
              </FirstCard>
            ) : (
              <Card>
                <ImageContainer>
                  <img
                    alt={`${cardInfo.title} icon`}
                    src={cardInfo.icon}
                    style={{ width: 25, height: 25, marginLeft: 20 }}
                  />
                  <CardTitle>{cardInfo.title}</CardTitle>
                </ImageContainer>
                <CardValue>{cardInfo.value}</CardValue>
              </Card>
            ),
          )}
        </CardsContainer>
      </CardRulesContainer>
    </Modal>
  );
}

export default memo(CampaignModal);
