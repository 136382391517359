import { HeaderContainer, Logo } from '../styles';
import { Title } from './styles';

function Header(): JSX.Element {
  return (
    <HeaderContainer>
      <Logo />
      <Title>Login</Title>
    </HeaderContainer>
  );
}

export default Header;
