import { memo, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from 'helpers/debounce';
import LogoDark from 'assets/svg/logo_horizontal.svg';
import LogoSymbol from 'assets/svg/logo_symbol.svg';
import { Establishment } from 'interfaces/establishment';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { getEstablishmentName, getNamePlaceholder } from 'helpers/stringHelper';
import SuperFilter from 'components/SuperFilter';
import { MenuItem as IMenuItem } from 'interfaces/menuItem';
import {
  Container,
  Logo,
  Menu,
  MenuItem,
  ToggleButton,
  LeftOutlinedIcon,
  RightOutlinedIcon,
  EstablishmentMenu,
} from './styles';
import data from './data';

function MainDrawer() {
  const establishment = useSelector<ReduxStore, Establishment | null>(
    state => state.establishment.currentEstablishment,
  );
  const [open, setOpen] = useState(true);
  const [menuData, setMenuData] = useState<IMenuItem[]>([]);
  const [superFilterVisible, setSuperFilterVisible] = useState(false);
  const history = useHistory();

  const { pathname } = history.location;
  const activePath = `/${pathname.split('/')[1]}`;

  useEffect(() => {
    const userRoles = establishment?.employees
      ? establishment?.employees[0]?.roles.map(x => x.name.toLowerCase())
      : [];

    if (userRoles) {
      setMenuData(
        data.filter(
          x =>
            x.roles &&
            x.roles.some(y => userRoles.indexOf(y.toLowerCase()) > -1),
        ),
      );
    } else {
      setMenuData(data.filter(x => !x.roles));
    }
  }, [establishment?.employees]);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    // use debounce to prevent excessive calls
    const resizeListener = debounce(() => {
      // close or open drawer based on screen length
      setOpen(window.innerWidth > 768);
    }, 200);

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const handleMenuItem = useCallback(
    (route: string) => () => {
      history.push(route);
    },
    [history],
  );

  const handleSuperFilter = useCallback(() => {
    setSuperFilterVisible(true);
  }, []);

  return (
    <Container className={open ? '' : 'closed'}>
      <div>
        <Logo
          src={open ? LogoDark : LogoSymbol}
          style={!open ? { width: 'inherit' } : { width: 140 }}
          className={open ? '' : 'closed'}
        />
        <ToggleButton onClick={toggleOpen} className={open ? '' : 'closed'}>
          {open ? <LeftOutlinedIcon /> : <RightOutlinedIcon />}
        </ToggleButton>
        <Menu
          selectedKeys={[activePath || '/establishment']}
          className={open ? '' : 'closed'}
        >
          {menuData.map(item => (
            <MenuItem
              onClick={handleMenuItem(item.route)}
              key={item.route}
              icon={item.icon}
              className={open ? 'opened' : ''}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>

        <SuperFilter
          visible={superFilterVisible}
          setVisible={setSuperFilterVisible}
        />

        <EstablishmentMenu
          onClick={handleSuperFilter}
          className={`btn-clean ${open ? '' : 'closed'}`}
        >
          <div className="placeholder">
            {establishment?.logo ? (
              <div
                style={{
                  backgroundImage: `url(${establishment.logo.location})`,
                }}
              />
            ) : (
              <span>{getNamePlaceholder(establishment)}</span>
            )}
          </div>
          <span className="company-name">
            {getEstablishmentName(establishment)}
          </span>
        </EstablishmentMenu>
      </div>
    </Container>
  );
}

export default memo(MainDrawer);
