import styled from 'styled-components';
import { Button as btn, Modal as antdModal, Typography } from 'antd';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  span.ant-modal-close-x {
    color: #101116;

    > span {
      background: #f1f1f3;
      padding: 5px;
      border-radius: 500%;
    }
  }

  div.modal-content {
    margin: 24px 0;
    border-bottom: 1px solid #e3e4e7;
    text-align: center;
    padding-bottom: 20px;

    > a {
      background: #f1f1f3;
      color: #848589;
      padding: 0px 20px;
      border-radius: 3px;
      width: 100%;
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      font-size: 19px;
      font-weight: 600;
    }
  }

  div.modal-header {
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 600;
    border-bottom: 1px solid #e3e4e7;
    padding-top: 20px;
    padding-bottom: 25px;
    color: #101116;

    div.placeholder {
      background: #080f38;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      span {
        color: white;
        font-weight: bold;
      }

      > div {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
      }
    }
  }
`;

export const ListItem = styled.ul`
  list-style: none;
  padding: 0;

  li + li {
    margin-top: 15px;
  }

  li > button {
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    > span {
      font-size: 16px;
      font-weight: 600;
      color: #65666b;
    }

    div.placeholder {
      background: #ccc;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      span {
        color: white;
        font-weight: bold;
      }

      > div {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
      }
    }
  }
`;

export const Button = styled(btn)``;

export const HighlightText = styled(Typography.Text)`
  color: #65666b;
  font-size: 19px;

  span {
    background: #080f38;
    color: #fff;
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
    line-height: 12px;
    border-radius: 50%;
    font-size: 14px;
    position: relative;
    top: -3px;
    margin-right: 5px;
  }
`;
