import { useEffect, useState } from 'react';
import { Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginWithSocial } from 'store/ducks/auth';
import { useQuery } from 'hooks/useQuery';
import { usePermissions } from 'hooks/usePermissions';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';

import { FormContainer, HighlightText, FooterText, Align } from '../styles';
import { NoAccountText, Tabs, TabBarItem } from './styles';

import Header from './Header';
import { LoginLayout } from '../Layout';

const { TabPane } = Tabs;

function LoginForm(): JSX.Element {
  const dispatch = useDispatch();
  const { clearCurrentEstablishment, addLoggedUser } = usePermissions();
  const query = useQuery();
  const location = useLocation();
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  useEffect(() => {
    const params = location.search;

    clearCurrentEstablishment();
    if (params && params.includes('code=') && params.includes('scope=')) {
      dispatch(
        loginWithSocial(params, user => {
          if (redirectUrl) window.location.href = redirectUrl;
          addLoggedUser(user);
        }),
      );
    }
  }, [
    dispatch,
    location.search,
    redirectUrl,
    clearCurrentEstablishment,
    addLoggedUser,
  ]);

  return (
    <LoginLayout>
      <Align>
        <Header />
        <FormContainer>
          <Tabs defaultActiveKey="1" tabBarGutter={0}>
            <TabPane key="1" tab={<TabBarItem>E-mail</TabBarItem>}>
              <EmailForm />
            </TabPane>
            <TabPane key="2" tab={<TabBarItem>Telefone</TabBarItem>}>
              <PhoneForm />
            </TabPane>
          </Tabs>
          <Row justify="center">
            <NoAccountText>
              Ainda não possui conta?{' '}
              <Link to={`/signup${window.location.search}`}>
                <HighlightText strong>Cadastre-se</HighlightText>
              </Link>
            </NoAccountText>
          </Row>
        </FormContainer>
      </Align>
      <FooterText>
        © SouFan - Todos os direitos reservados. Termos de uso e Política de
        privacidade.
      </FooterText>
    </LoginLayout>
  );
}

export default LoginForm;
