import { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, notification } from 'antd';
import { translate } from 'config/i18n';

import Page from 'components/Page';
import ImagePlaceholderIcon from 'assets/svg/fanapp-icon-image-placeholder.svg';

import {
  Establishment,
  EstablishmentLogo,
  EstablishmentState,
} from 'interfaces/establishment';
import { ReduxStore } from 'interfaces/reduxStore';
import { useDispatch, useSelector } from 'react-redux';

import MailIcon from 'assets/svg/fanapp-icon-email.svg';
import PhoneIcon from 'assets/svg/fanapp-icon-phone.svg';
import EditIcon from 'assets/svg/edit.svg';

import ConfirmModal from 'components/ConfirmModal';
import { gutter } from 'constants/styles';
import { actionClearCurrentEstablishment } from 'store/ducks/establishment';
import api from 'services/api';
import { removeCurrentEstablishment } from 'services/storage';
import { usePermissions } from 'hooks/usePermissions';
import { Roles } from 'constants/roles';
import {
  formatCnpj,
  formatCpf,
  getEstablishmentName,
} from 'helpers/stringHelper';
import { Header, Button, Content } from './styles';

function EstablishmentDetail() {
  const establishment = useSelector<ReduxStore, Establishment>(
    state => state.establishment.currentEstablishment as Establishment,
  );

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [tabs, setTabs] = useState([
    {
      label: translate('establishment.general'),
      route: '/establishment',
    },
  ]);
  const [loading, setLoading] = useState(false);
  // const [ isAdmin, setIsAdmin ] = useState(false);
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const dispatch = useDispatch();
  const { clearCurrentEstablishment, has } = usePermissions();
  const history = useHistory();

  function getLogo(image: EstablishmentLogo | undefined) {
    return image ? (
      <div
        className="imageContainer"
        style={{
          background: `url(${establishment?.logo?.location})`,
        }}
      />
    ) : (
      <div className="imageContainer">
        <div className="imagePlaceHolder">
          <img
            src={ImagePlaceholderIcon}
            alt={translate('establishment.logo_placeholder')}
          />
        </div>
      </div>
    );
  }

  const confirmRemoveEstablishment = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(`trade/establishments/${currentEstablishment?.id}`);
      setConfirmModalVisible(false);

      clearCurrentEstablishment();
      dispatch(actionClearCurrentEstablishment());
      removeCurrentEstablishment();
      history.push('/establishments');

      notification.success({
        message: translate('general.success'),
        description: translate('establishment.establishment_was_removed'),
      });
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    }
  }, [currentEstablishment, dispatch, history, clearCurrentEstablishment]);

  useEffect(() => {
    if (has([], [Roles.ADMIN])) {
      setTabs(x => [
        ...x,
        {
          label: translate('establishment.preferences'),
          route: '/establishment/preferences',
        },
      ]);
    }
  }, [has]);

  function getDocument() {
    if (establishment?.document) {
      return formatCnpj(establishment?.document);
    }

    return formatCpf(establishment?.partnerDocument, false);
  }

  return (
    <Page
      title="Estabelecimento"
      tabs={tabs}
      SubHeader={
        <Header>
          {has([], [Roles.ADMIN]) && (
            <Button
              onClick={() => {
                history.push(`/establishments/update/${establishment?.id}`);
              }}
              htmlType="button"
              type="ghost"
              className="ghost"
            >
              <img
                style={{ marginRight: 10 }}
                src={EditIcon}
                alt="Editar estabelecimento"
              />
              Editar estabelecimento
            </Button>
          )}
          {/* {
            isAdmin
            && (
              <Button onClick={() => setConfirmModalVisible(true)}
              className="btn-danger" htmlType="button" type="primary">
                Excluir
                </Button>
                )
              } */}
        </Header>
      }
    >
      <Content>
        <Row gutter={gutter} className="face-sheet">
          <Col sm={24}>{getLogo(establishment?.logo)}</Col>
        </Row>
        <Row gutter={gutter} className="main-infos">
          <Col sm={24} md={12}>
            <h1>{getEstablishmentName(establishment)}</h1>
            <p className="description">{establishment?.description}</p>
          </Col>
        </Row>
        <Row gutter={gutter} className="detailed-infos">
          <Col sm={24} md={12}>
            <h1>{translate('establishment.managing_partner')}</h1>
            <div className="info-content">
              <p>{establishment?.partnerName}</p>
              <p>{formatCpf(establishment?.partnerDocument)}</p>
            </div>

            <h1>{translate('establishment.contact')}</h1>
            <div className="contact-content">
              <img src={MailIcon} alt={translate('establishment.email_icon')} />
              <p>{establishment?.email}</p>
            </div>
            <div className="contact-content">
              <img
                src={PhoneIcon}
                alt={translate('establishment.phone_icon')}
              />
              <p>{establishment?.phone}</p>
            </div>

            <a
              rel="noopener noreferrer"
              target="_blank"
              href={establishment.website}
            >
              {establishment?.website}
            </a>
          </Col>
          <Col sm={24} md={12}>
            <div className="map-content">
              <span className="title">
                {translate('establishment.address')}
              </span>
              <span className="address">
                {`${establishment?.line1}, ${establishment?.line2} ${
                  establishment?.line3 ? '-' : ''
                } ${establishment?.line3}
                ${establishment?.postalCode ? '- CEP ' : ''} ${
                  establishment?.postalCode
                } ${establishment.city}-${establishment.state}`}
              </span>
            </div>

            <div className="fiscal-content">
              <h1>{translate('establishment.tax')}</h1>
              <p>
                <strong>
                  {translate(
                    `establishment.${
                      establishment?.document ? 'document' : 'partner_document'
                    }`,
                  )}
                  :{' '}
                </strong>
                {getDocument()}
              </p>
              {establishment?.tradeName && (
                <p>
                  <strong>{translate('establishment.company_name')} </strong>
                  {establishment?.tradeName}
                </p>
              )}
              {establishment?.townRegistration && (
                <p>
                  <strong>
                    {translate('establishment.town_registration')}{' '}
                  </strong>
                  {establishment?.townRegistration}
                </p>
              )}
              {establishment?.stateRegistration && (
                <p>
                  <strong>
                    {translate('establishment.state_registration')}{' '}
                  </strong>
                  {establishment?.stateRegistration}
                </p>
              )}
            </div>
          </Col>
        </Row>
      </Content>

      <ConfirmModal
        loading={loading}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        confirmText={translate('establishment.remove_confirmation')}
        confirmCallback={confirmRemoveEstablishment}
      />
    </Page>
  );
}

export default memo(EstablishmentDetail);
