import styled from 'styled-components';
import { Typography, Tabs as DefaultTabs, Checkbox } from 'antd';

export const ForgetPasswordLink = styled.a`
  margin-bottom: 1rem;
  color: #65666b;
  font-weight: 400;

  margin-top: -5px;
`;

export const NoAccountText = styled(Typography.Text)`
  margin-top: 2rem;
  font-weight: 300;
`;

export const Title = styled.h1`
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2rem;
  margin-top: 4.5rem;
  color: #101116;
`;

export const Tabs = styled(DefaultTabs)`
  display: block;

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 100%;
    color: #65666b;
    font-weight: 400;
    display: inline-block;
    text-align: center;
  }

  .ant-tabs-ink-bar-animated.ant-tabs-ink-bar {
    height: 2px;
  }

  .ant-tabs-tab-active > .ant-tabs-tab-btn {
    color: #101116;
    font-weight: 600;
  }

  .ant-tabs-nav::before {
    display: none;
  }
`;

export const Check = styled(Checkbox)`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-checkbox-inner {
    border-radius: 50%;
    border: 1px solid #909193;
  }
`;

export const CheckText = styled.a`
  margin-bottom: 1rem;
  color: #65666b;
  font-weight: 400;
`;

export const TabBarItem = styled.div`
  font-size: 1.1rem;
`;

export const FormTitle = styled.h2`
  font-weight: 400;
  margin-top: 2.5rem;
  margin-bottom: 0.6rem;
  font-size: 1rem;
  color: #101116;
`;

export const InputTitle = styled.h2`
  font-weight: 400;
  margin-top: 2rem;
  margin-bottom: 0.6rem;
  font-size: 1rem;
  color: #101116;
`;
