import { memo, useState, useEffect, useCallback } from 'react';
import {
  PlusOutlined,
  EllipsisOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import Page from 'components/Page';
import DataTable from 'components/DataTable';

import api from 'services/fanappApi';

import { EstablishmentState } from 'interfaces/establishment';
import { notification, Menu } from 'antd';

import { defaultPageSize } from 'constants/styles';
import { Campaign, CampaignType } from 'interfaces/campaign';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';

import { format, parseISO } from 'date-fns';

import {
  Header,
  Button,
  Content,
  Dropdown,
  DropdownStatus,
  MenuItem,
  DateColumn,
  CampaignTypeCard,
} from './styles';
import ExtendPeriodModal from './ExtendPeriodModal';

function Campaigns() {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState<Array<any>>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
  const [extendPeriodModalVisible, setExtendPeriodModalVisible] = useState(
    false,
  );
  const [rawCampaigns, setRawCampaigns] = useState<Array<Campaign>>([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const [categoryTypeList, setCategoryTypeList] = useState<Array<CampaignType>>(
    [],
  );

  const columns: any = [
    {
      title: 'Título',
      dataIndex: 'title',
    },
    {
      title: 'Categoria',
      dataIndex: 'type',
      sorter: false,
    },
    {
      title: 'Publicação',
      dataIndex: 'startEnd',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: false,
    },
  ];

  const handleChangeTable = useCallback(
    async (page?: number | undefined, pageSize?: number | undefined) => {
      try {
        setLoading(true);
        api.get('customer/campaign-types').then(({ data }) => {
          setCategoryTypeList(data.data);
        });

        const response = await api.get(
          `trade/establishments/${currentEstablishment?.id}/campaigns?page=${
            page || 1
          }&pageSize=${pageSize || defaultPageSize}`,
        );

        setTotal(response.headers['x-total'] || 0);

        setRawCampaigns(response.data.data);
      } catch {
        notification.error({
          message: 'Erro!',
          description: 'Ocorreu um erro com a requisição. Tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [currentEstablishment],
  );

  const DropdownMenu = useCallback(
    campaign => (
      <Menu>
        <MenuItem
          onClick={() => {
            history.push(`/campaigns/update/${campaign.id}`);
          }}
        >
          Editar campanha
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(`/campaigns/create/${campaign.id}`, {
              duplicate: true,
            });
          }}
        >
          Duplicar
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectedCampaign(campaign);
            setExtendPeriodModalVisible(true);
          }}
        >
          Extender
        </MenuItem>
      </Menu>
    ),
    [history],
  );

  function getStatus(campaign: Campaign) {
    if (!campaign.disabledAt && !campaign.publishedAt) return 'Limitada';
    if (!campaign.disabledAt && campaign.publishedAt) return 'Publicada';
    return 'Desabilitada';
  }

  const changeStatus = useCallback(
    async (campaign, status) => {
      try {
        setLoading(true);

        await api.post(`trade/campaigns/${campaign.id}/change-status`, {
          status,
        });
        handleChangeTable();
        notification.success({
          message: 'Sucesso!',
          description: 'Campanha salva',
        });
      } catch {
        notification.error({
          message: 'Erro',
          description: 'Ocorreu um erro com a requisição. Tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [handleChangeTable],
  );

  const DropdownMenuStatus = useCallback(
    campaign => (
      <Menu>
        <MenuItem onClick={() => changeStatus(campaign, 'published')}>
          Publicada
        </MenuItem>
        <MenuItem onClick={() => changeStatus(campaign, 'limited')}>
          Limitada
        </MenuItem>
        <MenuItem onClick={() => changeStatus(campaign, 'disabled')}>
          Desabilitada
        </MenuItem>
      </Menu>
    ),
    [changeStatus],
  );

  const updateDate = (date: string) => {
    setRawCampaigns(
      rawCampaigns.map(x =>
        x.id === selectedCampaign?.id ? { ...x, endAt: date } : x,
      ),
    );
    setSelectedCampaign(undefined);
  };

  useEffect(() => {
    async function getCampaignsFromServer() {
      handleChangeTable();
    }

    getCampaignsFromServer();
  }, [currentEstablishment, handleChangeTable]);

  const getTypeName = useCallback(
    (typeId?: string) => {
      if (!typeId) return '';

      const type = categoryTypeList.find(y => y.id === typeId);

      switch (type?.name) {
        case 'credit':
          return 'Crédito';
        case 'ticket':
          return 'Ingresso';
        default:
          return 'Produto';
      }
    },
    [categoryTypeList],
  );

  useEffect(() => {
    setCampaigns(
      rawCampaigns.map((x: Campaign) => ({
        ...x,
        startEnd: (
          <>
            <DateColumn>
              {x.startAt || x.endAt ? (
                <div style={{ textAlign: 'right' }}>
                  {x.startAt
                    ? `Início: ${format(parseISO(x.startAt!), 'dd/MM/yyyy')}`
                    : 'Início: -'}
                  <br />
                  {x.endAt
                    ? `Fim: ${format(parseISO(x.endAt!), 'dd/MM/yyyy')}`
                    : 'Fim: -'}
                </div>
              ) : (
                '-'
              )}
            </DateColumn>
          </>
        ),
        type: (
          <CampaignTypeCard
            type={
              categoryTypeList.find(y => y.id === x.campaignTypeId)?.name || ''
            }
          >
            <span>{getTypeName(x.campaignTypeId)}</span>
          </CampaignTypeCard>
        ),
        status: (
          <>
            <DropdownStatus
              trigger={['click']}
              overlay={() => DropdownMenuStatus(x)}
              placement="bottomLeft"
              status={getStatus(x)}
            >
              <div>
                {getStatus(x)}
                <DownOutlined className="ml-1" />
              </div>
            </DropdownStatus>
          </>
        ),
        actions: (
          <>
            <Dropdown
              trigger={['click']}
              overlay={() => DropdownMenu(x)}
              placement="bottomLeft"
            >
              <EllipsisOutlined className="actions-icon" />
            </Dropdown>
          </>
        ),
      })),
    );
  }, [
    rawCampaigns,
    DropdownMenu,
    DropdownMenuStatus,
    categoryTypeList,
    getTypeName,
  ]);

  function navigateToNewCampaign() {
    history.push('/campaigns/create');
  }

  return (
    <Page
      displayDrawer
      title="Campanhas"
      tabs={[
        {
          label: 'Geral',
          route: '/campaigns',
        },
      ]}
      SubHeader={
        <Header>
          <Button
            onClick={navigateToNewCampaign}
            htmlType="button"
            type="primary"
            size="middle"
          >
            <PlusOutlined /> Criar campanha
          </Button>
        </Header>
      }
    >
      <Content>
        <DataTable
          total={total}
          loading={loading}
          onChange={handleChangeTable}
          columns={columns}
          dataSource={campaigns}
          rowKey="id"
        />
      </Content>

      <ExtendPeriodModal
        campaign={selectedCampaign as Campaign}
        visible={extendPeriodModalVisible}
        setVisible={setExtendPeriodModalVisible}
        callback={updateDate}
      />
    </Page>
  );
}

export default memo(Campaigns);
