/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react';
import DropzoneComponent, { DropzoneOptions, FileError } from 'react-dropzone';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import IndicatorIcon from 'assets/svg/icon-camera.svg';

import { notification } from 'antd';
import { CampaignImage } from 'interfaces/campaign';
import {
  ImageUpload,
  Indicator,
  IconContainer,
  PreviewContainer,
  DescriptionContainer,
  RemoveLayer,
  RemoveIconContainer,
} from './styles';

interface IDropzoneProps extends DropzoneOptions {
  logoPreview: string | null | undefined;
  alt: string | undefined;
  maxSize?: number;
  image?: CampaignImage;
  accept?: string | string[];
  width?: number;
  rounded?: boolean;
  loading?: boolean;
  showIndicator?: boolean;
  onClean: (e: any) => void;
}

export default function Dropzone({
  onDropAccepted,
  onClean,
  logoPreview = '',
  maxSize,
  accept,
  image,
  width,
  rounded,
  loading,
  showIndicator,
  alt,
}: IDropzoneProps): JSX.Element {
  const onDropRejected = useCallback(
    rejectedFiles => {
      const imgData = rejectedFiles[0];

      imgData.errors.forEach((error: FileError) => {
        if (error.code === 'file-too-large') {
          const maxFileSize = maxSize || 1048576;
          notification.error({
            message: 'Erro!',
            description: `Por favor selecione um arquivo com menos de ${
              maxFileSize / 1048576
            }mb.`,
          });
        }
      });
    },
    [maxSize],
  );

  return (
    <DropzoneComponent
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      accept={accept}
      maxSize={maxSize}
      multiple={false}
      data-testid="dropzone"
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <ImageUpload
          $width={width}
          rounded={rounded}
          $loading={loading}
          hasPreview={!!logoPreview}
          {...getRootProps()}
          hasLogoPreview={!!logoPreview}
          isDragActive={isDragActive}
        >
          <input {...getInputProps()} />

          {logoPreview ? (
            <>
              <PreviewContainer onClick={onClean}>
                <RemoveLayer className="remove-layer">
                  <RemoveIconContainer className="icon-container">
                    <DeleteOutlined color="red" />
                  </RemoveIconContainer>
                </RemoveLayer>
                <img src={logoPreview} alt={alt} />
              </PreviewContainer>

              <DescriptionContainer>
                {image?.name}
                {/* <span>800kb</span> */}
              </DescriptionContainer>
            </>
          ) : (
            <>
              <IconContainer className="icon-container">
                <CloudUploadOutlined />
              </IconContainer>
              {isDragActive ? (
                <span>Solte a imagem aqui</span>
              ) : (
                <>
                  <span>
                    Arraste e solte um arquivo ou clique para adicionar
                  </span>
                </>
              )}
            </>
          )}
          {showIndicator && (
            <Indicator data-testid="dropzone-indicator">
              <img src={IndicatorIcon} alt="Indicador de upload" />
            </Indicator>
          )}
        </ImageUpload>
      )}
    </DropzoneComponent>
  );
}

Dropzone.defaultProps = {
  maxSize: 1048576,
  width: undefined,
  rounded: false,
  loading: false,
  showIndicator: false,
  accept: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
  ],
};
