import styled from 'styled-components';
import { Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  display: flex;
  span.ant-modal-close-x {
    color: #fe6a21;
  }
`;

export const ModalContainer = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  width: 480px;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 33.18px;
  color: #404145;
  line-height: 37px;
`;

export const SubTitle = styled.h2`
  font-weight: 600;
  font-size: 23.04px;
  color: #404145;
  line-height: 30px;
  margin-bottom: 20px;
`;

export const HeaderDetails = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const ItemTitle = styled.h2`
  font-weight: 600;
  font-size: 16px;
  color: #404145;
  line-height: 20px;
  margin-right: 15px;
`;

export const WhiteItemTitle = styled.h2`
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  line-height: 20px;
  margin-left: 15px;
`;

export const ItemInfo = styled.h2`
  font-weight: 400;
  font-size: 16px;
  color: #404145;
  line-height: 20px;
`;

export const RulesContainer = styled.div`
  margin-top: 50px;
`;

export const RuleContainer = styled.div`
  margin-top: 30px;
  width: 48%;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardRulesContainer = styled.div`
  display: flex;
  margin-top: 45px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 10px;
  background-color: #ffffff;
  width: 230px;
`;

export const FirstCard = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 10px;
  width: 230px;
  background-color: #6126fc;
`;

export const CardValue = styled.h1`
  font-weight: 600;
  font-size: 20px;
  color: #404145;
  line-height: 20px;
  margin-left: 60px;
`;

export const CardTitle = styled.h1`
  font-weight: 600;
  font-size: 16px;
  color: #404145;
  line-height: 20px;
  margin-left: 15px;
`;

export const WhiteCardValue = styled.h1`
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  line-height: 20px;
  margin-left: 60px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
  padding-right: 70px;
`;

export const Inactive = styled.h2`
  font-weight: 400;
  font-size: 14px;
  color: #a8a9ac;
  line-height: 18px;
`;

export const RulesTitle = styled.h2`
  font-weight: 600;
  font-size: 23.04px;
  color: #404145;
  line-height: 30px;
`;

export const ActiveLabel = styled.h2`
  font-weight: 600;
  font-size: 14px;
  color: #229dff;
  line-height: 18px;
`;

export const ImageContainer = styled.div`
  display: flex;
`;
