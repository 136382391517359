import { ReduxAction } from 'interfaces/redux';
import { User, UserState } from 'interfaces/user';
import { Dispatch } from 'redux';
import { showError } from 'services/error';
import api from 'services/api';
import { API_ENDPOINT } from 'constants/environment';
import { getUser, storeUser } from 'services/storage';
import { translate } from 'config/i18n';
import { actionSetUser, login, LoginInput } from './auth';

export enum UserTypes {
  LOADING = 'USER/LOADING',
  STOP_LOADING = 'USER/STOP_LOADING',
}

const initialState: UserState = {
  loading: false,
};

export default function userActions(
  state: UserState = initialState,
  action: ReduxAction<UserTypes>,
): UserState {
  switch (action.type) {
    case UserTypes.LOADING:
      return { ...state, loading: true };
    case UserTypes.STOP_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const actionLoading = (): ReduxAction<UserTypes> => ({
  type: UserTypes.LOADING,
});

export const actionStopLoading = (): ReduxAction<UserTypes> => ({
  type: UserTypes.STOP_LOADING,
});

export interface SignupInput {
  phone?: string;
  email?: string;
  redirect?: string | null;
}

export const signup = (
  input: SignupInput,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    await api.post('register', {
      ...input,
      redirect: input.redirect || `${API_ENDPOINT}/establishment`,
    });

    if (successCallback) successCallback();
  } catch (error: any) {
    if (error.response?.status === 409) {
      showError(
        translate('auth.field_already_registered', {
          context: `auth.${input.phone ? 'phone' : 'email'}`,
        }),
      );
    } else {
      showError(translate('general_messages.request_error'));
    }
  }
  dispatch(actionStopLoading());
};

export const passwordRecovery = (
  input: SignupInput,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    await api.post('reset-password', {
      ...input,
      redirect: input.redirect || `${API_ENDPOINT}/establishment`,
    });

    if (successCallback) successCallback();
  } catch (error) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};

export const confirmSignup = (
  code: string,
  successCallback?: () => void,
  errorCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    await api.post('register/verify-code', { code });

    if (successCallback) successCallback();
  } catch (error) {
    showError(translate('general_messages.request_error'));
    if (errorCallback) errorCallback();
  }
  dispatch(actionStopLoading());
};

export const confirmRecovery = (
  code: string,
  successCallback?: () => void,
  errorCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    await api.post('reset-password/verify-code', { code });

    if (successCallback) successCallback();
  } catch (error) {
    showError(translate('general_messages.request_error'));
    if (errorCallback) errorCallback();
  }
  dispatch(actionStopLoading());
};

export interface CompleteSignupInput {
  name: string;
  code: string;
  document: string;
  password: string;
}
export const completeSignup = (
  input: CompleteSignupInput,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const {
      data: { data },
    } = await api.post('users', { ...input, code: input.code.toUpperCase() });

    const loginInput: LoginInput = {
      password: input.password,
    };

    if (data.phone) loginInput.phone = data.phone;
    if (data.email) loginInput.email = data.email;

    login(loginInput, false, successCallback)(dispatch);
  } catch (error: any) {
    if (error.response?.status === 409) {
      showError(
        translate('auth.field_already_registered', {
          context: 'auth.document',
        }),
      );
    } else {
      showError(translate('general_messages.request_error'));
    }
  }
  dispatch(actionStopLoading());
};

export const completeRecovery = (
  input: CompleteSignupInput,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    await api.post('change-password', {
      ...input,
      code: input.code.toUpperCase(),
    });

    if (successCallback) successCallback();
  } catch (error) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};

export const uploadAvatar = (
  imageData: File,
  successCallback?: (user: User) => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const formData = new FormData();

    formData.append('avatar', imageData);

    const {
      data: { data },
    } = await api.put('me/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const user = getUser() as User;

    user.avatar = data;

    storeUser(user);
    dispatch(actionSetUser(user));

    if (successCallback) successCallback(user);
  } catch (error) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};

export const removeAvatar = (successCallback?: (user: User) => void) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(actionLoading());
  try {
    await api.delete('me/avatar');

    const user = getUser() as User;

    user.avatar = undefined;

    storeUser(user);
    dispatch(actionSetUser(user));

    if (successCallback) successCallback(user);
  } catch (error) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};

interface UpdateUserData {
  name: string;
  // document: string;
}

export const update = (
  user: UpdateUserData,
  successCallback?: (user: User) => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const {
      data: { data },
    } = await api.put('me', user);

    storeUser(data);
    dispatch(actionSetUser(data));

    if (successCallback) successCallback(data);
  } catch (error) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};
