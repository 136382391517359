import { useCallback, useState } from 'react';
import { Form, Input, Row } from 'antd';
import { UserState } from 'interfaces/user';
import { login, LoginInput } from 'store/ducks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { Store } from 'antd/lib/form/interface';
import { useQuery } from 'hooks/useQuery';
import { usePermissions } from 'hooks/usePermissions';
import { SubmitButton } from '../styles';
import {
  FormTitle,
  InputTitle,
  ForgetPasswordLink,
  Check,
  CheckText,
} from './styles';

function EmailForm(): JSX.Element {
  const dispatch = useDispatch();
  const { clearCurrentEstablishment, addLoggedUser } = usePermissions();
  const query = useQuery();
  const { loading } = useSelector<ReduxStore, UserState>(state => state.auth);
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  const handleForm = useCallback(
    ({ email, password, persist }: Store) => {
      const input: LoginInput = {
        email,
        password,
      };

      clearCurrentEstablishment();
      dispatch(
        login(input, persist, user => {
          if (redirectUrl) window.location.href = redirectUrl;
          addLoggedUser(user);
        }),
      );
    },
    [dispatch, redirectUrl, clearCurrentEstablishment, addLoggedUser],
  );

  return (
    <>
      <FormTitle>E-mail</FormTitle>
      <Form onFinish={handleForm}>
        <Form.Item
          name="email"
          validateFirst={false}
          rules={[
            {
              required: true,
              message: 'O campo email é obrigatório',
              validateTrigger: 'onBlur',
            },
            {
              type: 'email',
              message: 'O campo email é inválido',
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <InputTitle>Senha</InputTitle>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'O campo senha é obrigatório',
              validateTrigger: 'onBlur',
            },
            {
              min: 6,
              message: 'O campo senha deve conter no mínimo 6 caracteres',
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Row justify="space-between" align="middle">
          <Form.Item valuePropName="checked" name="persist">
            <Check>
              <CheckText>Manter conectado</CheckText>
            </Check>
          </Form.Item>

          <ForgetPasswordLink href={`/forgot-password?redirect=${redirectUrl}`}>
            Esqueceu a senha?
          </ForgetPasswordLink>
        </Row>

        <Row justify="center">
          <SubmitButton
            disabled={loading}
            htmlType="submit"
            type="primary"
            size="large"
          >
            {loading ? 'Enviando' : 'Confirmar'}
          </SubmitButton>
        </Row>
      </Form>
    </>
  );
}

export default EmailForm;
